<template>
	<div>
		<h1 class="white--text text-center">Félicitations {{ profileInfos.first_name }} !</h1>
		<h3 v-if="step === 3" class="white--text text-center mt-3">Tu as choisi ton prochain programme</h3>
		<h3 v-else class="white--text text-center mt-2">Programme "{{ categoryName }}" terminé</h3>
	</div>
</template>

<script>
export default {
	name: 'Congrats',
	props: {
		profileInfos: Object,
		categoryName: String,
		step: Number,
	},
};
</script>

<style></style>
