<template>
	<div class="mt-5 r-select-category-card">
		<div v-if="someRulesToDisplay.length === 0" class="rules">
			<div class="ruleChip skeleton" style="width: 90px"></div>
			<div class="ruleChip skeleton" style="width: 90px"></div>
			<div class="ruleChip skeleton" style="width: 99px"></div>
		</div>
		<div v-else class="rules">
			<div v-for="ruleTag in someRulesToDisplay" :key="ruleTag._id" class="ruleChip">{{ ruleTag.name }}</div>
			<div class="ruleChip more-rule-plus">+{{ totalRules - someRulesToDisplay.length }} autres</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'RuleBlockForSelectCategoryCard',
	data() {
		return { totalRules: 0, someRulesToDisplay: [] };
	},
	props: { exercisesWithRules: Array },
	mounted() {
		if (this.exercisesWithRules == null) return;

		// Filter rules to display
		const uniqueRules = [];
		this.exercisesWithRules.forEach(({ story_id }) => {
			story_id.forEach(({ rule_tags_id }) => {
				const indexOfExistedRule = uniqueRules.findIndex((ur) => ur._id === rule_tags_id._id);
				if (indexOfExistedRule === -1) uniqueRules.push(rule_tags_id);
			});
		});

		// Note: Some rule's name are long. Therefore, select rules that's name not so long.
		const temp = [];
		let index = 0;
		const limitDisplay = window.innerWidth > 600 ? 5 : 4;
		while (temp.length < limitDisplay && index < uniqueRules.length) {
			if (uniqueRules[index].name.length <= 25) temp.push(uniqueRules[index]);
			index++;
		}

		this.totalRules = uniqueRules.length;
		this.someRulesToDisplay = temp;
	},
};
</script>

<style lang="scss" scoped>
.r-select-category-card {
	min-height: 50px;

	.rules {
		display: flex;
		flex-wrap: wrap;
		max-width: 500px;
		margin: 0 auto;
		@media screen and (max-width: 600px) {
			justify-content: center;
		}
	}

	.ruleChip {
		border-radius: 8px;
		background-color: #f0f0f0;
		padding: 5px 12px;
		color: black;
		font-family: 'Lato';
		margin-bottom: 8px;
		margin-right: 4px;

		@media screen and (max-width: 600px) {
			padding: 4px 10px;
		}
	}

	.more-rule-plus {
		@media screen and (max-width: 600px) {
			display: block;
		}
	}

	.skeleton {
		height: 34px;
	}
}
</style>
