<template>
	<v-card
		class="category-card shadow-blue-not-openned"
		:class="{
			'step-3-bg-color': step === 3,
			'category-card-step-2-mobile': step === 2 && $vuetify.breakpoint.smAndDown,
		}"
	>
		<div class="cardInfo">
			<div>
				<div class="d-flex align-center title-block">
					<v-icon v-if="step === 3" size="25px" color="white">mdi-lock-open-variant</v-icon>
					<h2 class="title" :class="{ 'ortho-icon-white': step === 3 }">{{ category.name }}</h2>
				</div>
				<RuleBlockForSelectCategoryCard
					v-if="hideRuleTags !== true"
					:exercisesWithRules="category.exercises"
					class="mb-5"
				/>
				<div v-if="step === 3" class="exerciseProgress mt-1 mb-5">
					<v-progress-linear
						:value="0"
						class="progressBar"
						height="20"
						color="#8152f3"
						background-color="#b3b4fc"
					></v-progress-linear>
					<span v-if="totalSessions">0/{{ totalSessions }}</span>
					<span v-else>0/10</span>
				</div>
			</div>
			<v-btn v-if="step === 2" dark large :loading="loading" class="actionBtn" @click="selectNewCategory">
				<b><span class="btnLabel">Débloquer</span></b>
			</v-btn>
		</div>
		<div class="cardThumbnail">
			<v-img class="thumbnail" :src="step === 2 ? category.cover_image_locked : category.cover_image"> </v-img>
		</div>
	</v-card>
</template>

<script>
import RuleBlockForSelectCategoryCard from '@/components/reports/category/RuleBlockForSelectCategoryCard';

export default {
	name: 'CategoryCard',
	components: { RuleBlockForSelectCategoryCard },
	props: {
		category: Object,
		formationId: String,
		step: Number,
		hideRuleTags: Boolean,
		totalSessions: Number,
	},
	data() {
		return { loading: false };
	},
	methods: {
		async selectNewCategory() {
			this.loading = true;
			const categorySelected = await this.$store.dispatch('profile/selectNewCategory', {
				categoryId: this.category._id,
				formationId: this.formationId,
			});

			if (categorySelected.status == 200) {
				this.$emit('selectionSucceed', true);
				this.$emit('categorySelected', this.category);
				this.loading = false;
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.category-card {
	padding: 2rem;
	display: flex;
	gap: 10px;
	border-radius: 10px;
	width: 600px;
	min-height: 200px;

	.exerciseProgress {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 60%;
		position: relative;
		.progressBar {
			border-radius: 10px !important;
		}
		span {
			font-size: 18px;
			font-weight: bold;
			color: white;
			padding-left: 10px;
		}
		.trophy {
			z-index: 1;
			position: absolute;
			left: -10px;
			top: -1px;
		}
	}

	.cardInfo {
		width: 75%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		.title {
			font-weight: bold;
		}
		.actionBtn {
			max-width: 150px;
			text-transform: capitalize;
			.btnLabel {
				font-size: 18px;
			}
		}

		.rule-tags {
			display: flex;
			align-items: center;
		}
	}

	.cardThumbnail {
		width: 25%;
		display: flex;
		align-items: center;
		.thumbnail {
			width: 100%;
		}
	}
}

.step-3-bg-color {
	background-color: #8186e8;
}

@media screen and (max-width: 600px) {
	.category-card {
		display: flex;
		flex-direction: column-reverse;
		align-items: center;
		justify-content: center;
		padding: 1rem;
		.cardInfo {
			width: 100%;
			align-items: center;
		}
		.cardThumbnail {
			width: 60%;
		}

		.actionBtn {
			margin-top: 0.5rem;
			max-width: 100%;
			font-size: 15px;
		}
		.exerciseProgress {
			width: 100%;
		}

		.title-block {
			justify-content: center;
		}
	}
	.category-card-step-2-mobile {
		border: 0.5px solid #f0f0f1;
	}
}
</style>
